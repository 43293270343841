<template>
  <v-container fill-height fluid>
    <v-row justify="end" class="align-self-start">
      <v-col cols="auto">
        <tl-locale-selector />
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col>
        <v-form ref="form" lazy-validation autocomplete="offcheckoutform" @submit.prevent="submit">
          <v-card max-width="400" class="mx-auto">
            <v-card-title>{{ $t('reservations.checkout') }}</v-card-title>
            <v-card-text>
              <p>{{ $t('reservations.checkOutTablet') }}</p>
              <v-text-field
                type="email"
                v-model="email"
                autocomplete="offcheckoutemail"
                :label="$t('profile.info.email')"
                :rules="[ruleRequired, ruleEmail]"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text type="submit" color="primary" :loading="loading" :disabled="loading">
                {{ $t('reservations.checkout') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-row style="height: 80px" />
  </v-container>
</template>

<script>
import axios from '@/services/axios'
import errorService from '@/services/error-service'
import tlLocaleSelector from '@/components/shared/tl-locale-selector.vue'
import { required as ruleRequired, email as ruleEmail } from '@/services/input-rules'

export default {
  components: {
    tlLocaleSelector,
  },
  data: () => ({
    email: '',
    ruleRequired,
    ruleEmail,
    loading: false,
  }),
  methods: {
    submit() {
      this.loading = true
      // .post(`/reservations/checkout`, { params: { email: this.email } })
      axios
        .post(`/reservations/checkout`, { email: this.email })
        .then(() => {
          this.$store.dispatch('toast/success', this.$t('generic.success'))
          this.$refs.form.reset()
          this.email = ''
        })
        .catch(errorService.toast)
        .finally(() => (this.loading = false))
    },
  },
}
</script>
