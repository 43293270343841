import axios from 'axios'
import { refreshIfOld } from '@/services/version-service'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_URL_API + '/v1',
  suffix: '.json',
})

axiosInstance.interceptors.request.use(config => {
  let email = localStorage.getItem('user_email')
  let token = localStorage.getItem('user_token')
  if (email && token) {
    config.headers['X-USER-EMAIL'] = email
    config.headers['X-USER-TOKEN'] = token
  }
  return config
})

axiosInstance.interceptors.response.use(
  response => {
    refreshIfOld(response.headers['frontend-version'])
    return response
  },
  function(error) {
    return Promise.reject(error)
  }
)

export default axiosInstance
